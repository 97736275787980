<template>
	<div class="basket-form-delivery-courier text-center" id="basket-form-delivery-courier">
		<h4>Укажите адрес доставк<span @click.ctrl="fillForm">и</span></h4>
		<v-row class="mt-2 justify-center">
			<!--<v-col cols="12" sm="2">
			  <v-text-field v-model="form.ZIP" label="Индекс"
					  maxlength="16"
					  required />
			</v-col>-->
			<!--<v-col cols="12" sm="5">
			  <v-text-field v-model="form.COUNTRY"
					  label="Страна" readonly
					  maxlength="32"
					  required />
			</v-col>-->
			<v-col cols="12" sm="4">
				<v-text-field v-model="form.CITY"
							  label="Город"
							  maxlength="32"
							  :readonly="!!destination.city"
							  required/>
			</v-col>
			<!--<v-col cols="12" sm="4">
			  <v-text-field v-model="form.STATE" label="Район"
					  maxlength="32"
					  required />
			</v-col>-->
			<template v-if="destination.zones">
				<v-col cols="12" sm="4">
					<v-autocomplete
							v-model="form.STREET"
							:items="suggestedStreets"
							:loading="isSuggestLoading"
							:search-input.sync="suggestSearch"
							hide-no-data
							hide-selected
							no-filter
							item-text="short"
							item-value="short"
							return-value
							label="Улица, дом"
							placeholder="Улица, дом"
							@keyup="onAddressKeyUp"
							@change="onAddress"/>
					<!--<v-text-field v-model="form.STREET"
								  label="Улица"
								  maxlength="80"
								  @keyup="onAddressKeyUp"
								  required/>-->
				</v-col>
				<v-col cols="6" sm="4">
					<v-text-field v-model="form.APT"
								  label="Квартира/Офис"
								  maxlength="24"
								  required/>
				</v-col>
			</template>
			<template v-else>
				<v-col cols="12" sm="4">
					<v-text-field v-model="form.STREET"
								  label="Улица"
								  maxlength="80"
								  required/>
				</v-col>
				<v-col cols="12" sm="2">
					<v-text-field v-model="form.BUILDING"
								  label="Дом"
								  maxlength="24"
								  required/>
				</v-col>
				<v-col cols="6" sm="2">
					<v-text-field v-model="form.APT"
								  label="Квартира/Офис"
								  maxlength="24"
								  required/>
				</v-col>
			</template>

		</v-row>

		<template v-if="withMap">
			<div class="mt-4">
				<p v-if="deliveryCost===0">Для данного заказа доставка будет <b>бесплатной!</b></p>
				<p v-else-if="deliveryZone">Выбрана зона доставки #{{ deliveryZone.label }}, стоимость
					доставки: <b>{{ deliveryCost | nf }} ₽</b></p>
				<p v-else-if="deliveryCost">Для данного адреса стоимость
					доставки составляет: <b>{{ deliveryCost | nf }} ₽</b></p>
				<p v-else-if="form.deliveryZoneId">Для указанного адреса стоимость доставки будет рассчитана при подтверждении заказа по телефону</p>
				<p v-else>Пожалуйста, укажите адрес в форме выше для
					определения стоимости доставки</p>
			</div>
			<yandex-map :settings="mapSettings"
						:coords="map.center"
						:zoom="map.zoom"
						:controls="map.controls"
						:behaviors="map.behaviors"
						@map-was-initialized="onMapInited"
						class="basket-form-delivery-courier__map mt-8">
				<!--Markers-->

				<!--<ymap-marker v-for="(item, index) of items" :key="'point'+index"
							 :marker-id="'marker'+index"
							 :coords="item.coords"
							 :hint-content="item.hint"
							 :icon="item.icon"/>
							 -->

			</yandex-map>
		</template>

	</div>
</template>

<script>
	import BtnPrimary from '../../components/btn-primary';
	import BtnText from '../../components/btn-text';
	import {scrollTo} from '@/utils/utils';
	import {geocodeYandex} from '@/utils/geo';
	import axios from "axios";
	import vp from '@/mixins/vp';
	import {destinations, deliveryTypes, deliveryCost} from '@/store/delivery';

	import Config from '@/config';
	import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';

	let localYmaps;
	let map, point;

	//const ZONE_FILL_COLORS = ["#d1eccc88", "#c3e6ad88", "#b9d7b588"];
	//const ZONE_FILL_COLORS = ["#fcefc866", "#faf4e266", "#d6e8fd66"];
	const ZONE_FILL_COLORS = ["#fcefc833", "#faf4e233", "#d6e8fd33"];
	//const ZONE_FILL_COLORS = ["#faf7e988", "#f2f1f088", "#fdf5f588"];
	//const ZONE_STROKE_COLORS = ["#c3e6adaa", "#b9d7b5aa", "#6c98f866"];
	const ZONE_STROKE_COLORS = ["#6c98f866", "#6c98f866", "#6c98f866"];

	export default {
		name: "basket-form",
		components: {
			yandexMap,
			ymapMarker,
			BtnPrimary,
			BtnText,
		},
		mixins: [vp],
		props: {
			form: {},
		},
		data() {
			return {
				mapSettings: {
					apiKey: Config.YANDEX_MAP_API_KEY,
					lang: 'ru_RU',
					coordorder: 'latlong',
					enterprise: false,
					version: '2.1'
				},
				// карта зон доставки
				map: {
					center: [59.938955, 30.315644],
					zoom: 11,
					controls: ["zoomControl"],
					behaviors: ["dblClickZoom", "drag", "multiTouch"]
					//behaviors: []
				},
				pointCoords: null,
				suggestedStreets: null,
				isSuggestLoading: false,
				suggestSearch: null,
			}
		},
		watch: {
			destination() {
				if (this.destination?.mapCoords) {
					this.map.center = this.destination?.mapCoords;

					setTimeout(() => {
						this.drawZonesOnMap();
					}, 300);
				}
				this.form.deliveryZoneId = null;
				this.form.CITY = this.destination.city || "";
			}
		},
		computed: {
			withMap() {
				return !!this.destination?.mapCoords && this.pointCoords;
			},
			destination() {
				return destinations.find(el => el.id === this.form.destinationId);
			},
			deliveryZone() {
				return this.destination?.zones?.find(el => el.id === this.form.deliveryZoneId);
			},
			deliveryType() {
				return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
			},
			deliveryCost() {
				return deliveryCost(this.form, this.costBeforeDelivery);
			},
			costBeforeDelivery() {
				const productsTotal = this.itemsCost;
				const postcardCost = this.postcardsCost;
				return productsTotal + postcardCost;
			},
			itemsCost() {
				return this.items.reduce((sum, item) => sum + item.PRICE * Number(item.AMOUNT), 0);
			},
			items() {
				return this.$store.state.session?.basket;
			},
			postcardsCost() {
				return Number(this.form.POSTCARD_PRICE) || 0;
			},
		},
		methods: {
			onAddress() {
				const city = this.destination?.resolve;
				if (!city) return;
				const addr = city + ", " + this.form.STREET;
				geocodeYandex(addr).then((results) => {
					this.form.deliveryCoords = this.pointCoords = results[0]?.GeoObject?.Point?.pos.split(" ").reverse();
					//console.log("COORDS", coords);
					this.drawDeliveryPointOnMap();
				});

				setTimeout(() => {
					scrollTo("basket-form-delivery-courier", -120);
				}, 300);
			},
			onAddressKeyUp() {
				if (this.suggestSearch?.length < 2) return;
				const city = this.destination?.resolve;
				if (!city) return;
				const addr = city + ", " + this.suggestSearch;//this.form.STREET;
				//console.log("addr", addr, ymaps);
				this.isSuggestLoading = true;

				const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
				const token = Config.DADATA_API_KEY;

				const options = {
					method: "POST",
					mode: "cors",
					headers: {
						"Content-Type": "application/json",
						"Accept": "application/json",
						"Authorization": "Token " + token
					},
					body: JSON.stringify({query: addr})
				};
				fetch(url, options)
					.then(response => response.text())
					.then(result => {
						result = JSON.parse(result);
						//console.log("result", result);
						this.suggestedStreets = result?.suggestions?.map(el => ({
							...el,
							short: el.value.replace(/^.+?,(.*)$/, "$1")
						}));//.sort();
					})
					.catch(error => console.log("error", error))
					.finally(() =>{
						this.isSuggestLoading = false;
					});

				//const url = "https://suggest-maps.yandex.ru/v1/suggest?apikey="+Config.YANDEX_MAP_API_KEY+"&text="+encodeURIComponent(addr);
				//const url = "https://suggest-maps.yandex.ru/v1/suggest?apikey=9ea883e5-edd1-4fdb-a20d-b7538f0718b1&text="+encodeURIComponent(addr);
				/*const url = "https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey="+Config.YANDEX_MAP_API_KEY
					+"&suggest_apikey="+Config.YANDEX_MAP_API_KEY_SUGGEST;*/
				/*const url = "https://geocode-maps.yandex.ru/1.x/?apikey=" + Config.YANDEX_MAP_API_KEY_SUGGEST
					+ "&geocode=" + encodeURIComponent(addr)
					+ "&kind=house"
					+ "&format=json&found=5";*/
				/*const url = "https://suggest-maps.yandex.ru/v1/suggest?apikey="+Config.YANDEX_MAP_API_KEY_SUGGEST
					+"&types=geo"
					+"&text="+encodeURIComponent(addr)
					+"&lang=ru_RU&results=5";//&bbox=30.043382%2C59.744315%2C30.566435%2C60.09071&strict_bounds=0*/
				/*axios.post(url).then((response) => {
					const items = response?.data.results;
					// items - массив поисковых подсказок
					//console.log("SUGGEST, items", items);

					// для geocode-maps.yandex.ru
					//this.suggestedStreets = items?.map(el => ({...el, short: el.title.text}));//.sort();

					// для suggest-maps.yandex.ru
					this.suggestedStreets = items?.map(el => ({...el, short: el.title.text}));//.sort();

					this.isSuggestLoading = false;
				});*/
				/*ymaps?.suggest(addr, {results: 20}).then((items) => {
					// items - массив поисковых подсказок.
					console.log("SUGGEST, items", items);
					this.suggestedStreets = items?.map(el => ({...el, short: el.value.substr(city.length + 2)}));//.sort();
					this.isSuggestLoading = false;
				});*/
			},
			onMapInited(mapRef) {
				//console.log("onMapInited", mapRef);
				map = mapRef;

				this.drawZonesOnMap();
				this.drawDeliveryPointOnMap();

				//this.drawZonesOnMap();
			},
			drawZonesOnMap() {
				if (!map) return;
				map.geoObjects.removeAll();

				this.destination.zones?.filter(zone => !!zone.coords)
					.forEach((zone, index) => {

						const fillColor = ZONE_FILL_COLORS[index];
						const strokeColor = ZONE_STROKE_COLORS[index];

						// Создаем многоугольник, используя вспомогательный класс Polygon.
						const poly = new ymaps.Polygon(zone.coords, {
							// Описываем свойства геообъекта.
							// Содержимое балуна.
							hintContent: zone.label,
							zoneId: zone.id
						}, {
							zIndex: zone.zIndex,
							// Задаем опции геообъекта.
							// Цвет заливки.
							fillColor,
							strokeColor,
							//fillOpacity: 1,
							// Ширина обводки.
							strokeWidth: 7
						});

						/*poly.events.add('click', (e) => {
							//console.log("POLY clicked", poly.properties.get('zoneId'));
							this.form.deliveryZoneId = poly.properties.get('zoneId');
							const zone = this.destination.zones.find(el => el.id === this.form.deliveryZoneId)
							map.geoObjects.each((p, index) => {
								const z = this.destination.zones.find(el => el.id === p.properties.get('zoneId'));
								const color = z.id === zone.id ? "#d1eccc88" : ZONE_FILL_COLORS[index];
								p.options.set("fillColor", color);

								//if (z.zIndex > zone.zIndex) p.options.set("strokeColor", "#ffffff00");
								//else p.options.set("strokeOpacity", 1);
								setTimeout(() => {
									scrollTo("basket-form__address", -120);
								}, 300);
							});
						});*/

						// Добавляем многоугольник на карту.
						map.geoObjects.add(poly);
					});
			},
			drawDeliveryPointOnMap() {
				if (!map) return;

				// delete prev point?
				//console.log("POINT before", point);
				if (point) map.geoObjects.remove(point);

				/*point = new ymaps.Placemark(coords);
				map.geoObjects.add(point);*/
				const coords = this.pointCoords;
				const result = ymaps.geoQuery([{type: "Point", coordinates: coords}]);
				//console.log("POINT after", point);
				const points = result.addToMap(map);
				point = points.get(0);

				// проверям, в какую зону попадает точка
				const polys = map.geoObjects;
				const zones = [];
				polys.each(poly => {
					if (poly.geometry.getType() !== "Polygon") return;
					const zoneId = poly.properties.get('zoneId');
					const zone = this.destination.zones.find(el => el.id === zoneId);
					//console.log("POLY", zoneId);

					const objectsInsideCircle = points.searchInside(poly);
					objectsInsideCircle.each(p => {
						//console.log("\t", p.geometry.getType() === "Point");
						if (p.geometry.getType() === "Point") zones.push(zone);
					});
				});

				//console.log("point", point);
				//console.log("ZONES", zones.map(el => el.label));
				zones.sort((a, b) => {
					if (a.zIndex < b.zIndex) return 1;
					if (a.zIndex > b.zIndex) return -1;
					return 0;
				});
				if ( zones.length ) {
					const selectedZone = zones[0];
					//console.log("SELECTED zone: ", selectedZone);
					this.form.deliveryZoneId = selectedZone.id;
				}
				else {
					this.form.deliveryZoneId = this.destination.unknownZoneId;
				}
				//console.log("Zone ID: ", this.form.deliveryZoneId);

				setTimeout(() => {
					this.map.center = coords;
				}, 500);
			},
			fillForm() {
				this.form.CITY = "Санкт-Петербург";
				this.form.STREET = "Решетникова";
				this.form.BUILDING = "15";
				this.form.APT = "122";
				if ( this.destination.zones) this.onAddress();
			}
		},
		mounted() {
			loadYmap({
				apiKey: Config.YANDEX_MAP_API_KEY,
				//suggest_apikey: Config.YANDEX_MAP_API_KEY,
			}).then(() => {
				localYmaps = ymaps;
				//console.log("loadYmap inited", ymaps);
				///const suggestView1 = new ymaps.SuggestView('address');
			});
		},
	}
</script>

<style lang="scss">
	.basket-form-delivery-courier {
		&__map {
			height: 60vh;
			min-height: 400px;
			width: 100%;

			@include up($md) {
				//height: 100%;
			}
		}
	}
</style>