<template>
  <div class="basket-form-delivery-types text-center" id="basket-form-delivery-types">
	<h4>Вариант получения заказа</h4>
	<div class="mt-2 d-flex justify-center">
	  <v-radio-group v-model="form.deliveryTypeId" :row="isSM" @change="onSelect">
		<span v-for="(item, index) of types" :class="{'--disabled': item.id === 3 && !isExpressDelivery}">
		<v-radio
			:label="item.label"
			:value="item.id"
			:key="'delivery-type-'+index"
		/>
</span>
	  </v-radio-group>
	</div>
	<p v-if="form.deliveryTypeId === 3">Мы делаем экспресс-доставку в течение 3 часов с момента подтверждения заказа.
	  Менеджер обязательно свяжется с вами в ближайшие 15 минут.<br>
	  Оформить экспресс-доставку можно ежедневно с 10 до 16 часов, мы доставим ваш заказ в тот же день не позднее 19
	  часов.</p>
  </div>
</template>

<script>
import vp from '@/mixins/vp';
import {scrollTo} from '@/utils/utils';
import {destinations, deliveryTypes} from '@/store/delivery';

export default {
  name: "basket-form-delivery-types",
  props: {
	form: {},
  },
  mixins: [vp],
  data() {
	return {
	  isExpressDelivery: false
	}
  },
  watch: {},
  computed: {
	destination() {
	  return destinations.find(el => el.id === this.form.destinationId);
	},
	types() {
	  return deliveryTypes.filter(el => this.destination.deliveryTypeIds?.some(id => id === el.id));
	},
  },
  methods: {
	onSelect() {
	  this. resetAddressAndDeliveryFields();
	  setTimeout(() => {
		scrollTo("basket-form-delivery-types", -120);
	  }, 300);

	  this.$emit("select");
	},
	resetAddressAndDeliveryFields() {
	  this.form.STREET = ''
	  this.form.APT = '';
	  this.form.deliveryPlaceId = '';
	  this.form.DATE_DELIVERY = '';
	  this.form.TIME = '';
	},
	checkExpressDeliveryAvailability() {
	  const currentMoscowTime = new Date().toLocaleString("en-US", {timeZone: "Europe/Moscow"});
	  const hours = new Date(currentMoscowTime).getHours();

	  if (hours >= 10 && hours < 16 && !this.isExpressDelivery) {
		this.isExpressDelivery = true;
		this.$emit('express-delivery-change', this.isExpressDelivery);
	  } else if (hours >= 16 && this.isExpressDelivery) {
		this.isExpressDelivery = false;
		this.$emit('express-delivery-change', this.isExpressDelivery);
	  }
	},
  },
  mounted() {
	this.checkExpressDeliveryAvailability();
	this.timeInterval = setInterval(() => {
	  this.checkExpressDeliveryAvailability();
	  console.log("Interval executed", this.isExpressDelivery);
	}, 10000);
  },

  destroyed() {
	clearInterval(this.timeInterval);
  },
}
</script>

<style lang="scss">
.basket-form-delivery-types {
  & .--disabled {

  }

  & .--disabled::after {
	text-wrap: nowrap;
	content: "Экспресс-доставка доступна с 10:00 до 16:00";
	position: absolute;
	top: 30px;
	left: 50%;
	transform: translateX(-50%);
	color: $gray !important;
	padding: 5px;
	border-radius: 5px;
	opacity: 0;
	transition: opacity 0.3s ease;
  }

  & .--disabled:hover::after {
	opacity: 1;
  }

  .--disabled {
	& .v-radio {
	  opacity: 0.5;
	  pointer-events: none !important;
	}
  }

}
</style>