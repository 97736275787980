<template>
	<div class="basket-form-delivery-self text-center">
		<h4>Выберите пункт самовывоза</h4>
		<v-row class="mt-2">
			<v-col cols="12" sm="6">
				<yandex-map :settings="mapSettings"
							:coords="map.center"
							:zoom="map.zoom"
							:controls="map.controls"
							:behaviors="map.behaviors"
							@map-was-initialized="onMapInited"
							class="basket-form-delivery-self__map">

					<ymap-marker v-for="(item, index) of mapItems" :key="'self-point-'+index"
								 :marker-id="'place'+index"
								 :coords="item.coords"
								 :hint-content="item.hint"
								 :icon="item.icon" @click="(event)=>onMarkerClick(item, event)"/>

				</yandex-map>
			</v-col>
			<v-col cols="12" sm="6">
				<ul class="ml-4 mt-6">
					<li v-for="(place, i) of items"
						:key="i" :class="{active:selectedItem && selectedItem.ID===place.ID}"
						@click="onItemClick(place)">
						<h6>{{ place.NAME }}</h6>
						<div class="size2">{{ place.ADDRESS }}</div>
					</li>
				</ul>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import vp from '@/mixins/vp';
import Config from '@/config';
import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';
import {destinations} from "@/store/delivery";

let localYmaps;
let map;

const ICON_COLOR_DEFAULT = "rgb(32, 32, 32)";
const ICON_COLOR_ACTIVE = "rgb(192, 156, 94)";
const MAP_ITEM_ZOOM = 13;
const MAP_ITEM_MIN_ZOOM = 15;
const MAP_OFFSET_X_MANY = 0.05;
const MAP_OFFSET_X_ONE = 0.002;

export default {
	name: "basket-form",
	components: {
		yandexMap,
		ymapMarker,
	},
	mixins: [vp],
	props: {
		form: {},
	},
	data() {
		return {
			mapSettings: {
				apiKey: Config.YANDEX_MAP_API_KEY,
				lang: 'ru_RU',
				coordorder: 'latlong',
				enterprise: false,
				version: '2.1'
			},
			// карта зон доставки
			map: {
				center: [59.938955, 30.315644],
				zoom: 11,
				controls: ["zoomControl"],
				behaviors: ["dblClickZoom", "drag", "multiTouch"]
				//behaviors: []
			},
			items: null,
			mapItems: null,
			selectedItem: null
		}
	},
	watch: {
		items() {
			console.log("SELECTED", this.form.deliveryPlaceId);
			this.selectedItem = this.items.find(el => el.ID === this.form.deliveryPlaceId);
		}
	},
	computed: {
		destination() {
			return destinations.find(el => el.id === this.form.destinationId);
		},
		page() {
			return this.$store.state.page?.BasketController;
		},
	},
	methods: {
		onItemClick(item) {
			this.selectedItem = item;
			//scrollTo("map", -200);
			//console.log(item, "zoom?")
			//console.log("items", this.items.find(el => el.ID === item.ID))
			this.map.zoom = MAP_ITEM_ZOOM;
			setTimeout(() => {
				let coords = [...item.coords];
				//coords[1] += MAP_OFFSET_X_ONE;
				this.map.center = coords;

				this.mapItems = [];
				setTimeout(() => {
					this.mapItems = this.initItems();
				}, 150);
				/*const index = this.items.findIndex(el => el.ID === item.ID);
				this.items.splice(index, 1);
				item.icon.imageHref = '/static/pins/7.svg';
				setTimeout(()=>{
					this.items.splice(index, 0, item);
				},150);*/
			}, 300);

			this.form.deliveryPlaceId = item.ID;
			this.form.STREET = this.selectedItem.ADDRESS;
			this.$emit("select", item);
		},
		onMapInited(mapRef) {
			map = mapRef;
		},
		onMarkerClick(item) {
			//console.log("Marker", item);
			this.onItemClick(item);
		},
		initItems() {
			return this.page?.places?.filter(el => Number(el.CITY_ID) === Number(this.destination.cityId)).map(el => {
				//const phones = el.PHONE?.split(",").map(el => el.trim());
				//const emails = el.EMAIL?.split(",").map(el => el.trim());

				let item = {
					ID: el.ID,
					CITY_ID: el.CITY_ID,
					//logo: el.PREVIEW_PICTURE,
					NAME: el.NAME,
					ADDRESS: el.ADDRESS,
					//site: el.SITE,
					//tel: phones[0]?.replace(/\D/, ""),
					//emails,
					//phones,
					IBLOCK_SECTION_ID: el.IBLOCK_SECTION_ID,
					COLOR: ICON_COLOR_DEFAULT,
					icon: {
						layout: 'default#image',
						imageHref: this.selectedItem?.ID === el.ID ? '/static/pins/5.svg' : '/static/pins/6.svg',
						// imageSize: [42, 84],
						// imageOffset: [-30, -84],
						contentOffset: [0, 0],
						// contentLayout: '<div class="places__placemark-content"><img src="/static/pins/6.svg"><span class="d-none d-md-block" style="width:320px">$[properties.iconContent]</span></div>'
					}
				};
				const geo = el.GEO?.split(",");
				if (geo.length >= 2) {
					const lng = parseFloat(geo[0]);
					const lat = parseFloat(geo[1]);
					item.coords = [lat, lng];
				}
				return item;
			});
		},
	},
	mounted() {
		this.map.center = this.destination?.mapCoords;

		loadYmap({
			apiKey: Config.YANDEX_MAP_API_KEY,
		}).then(() => {
			localYmaps = ymaps;
			///const suggestView1 = new ymaps.SuggestView('address');
		});
		this.items = this.initItems();
		this.mapItems = this.initItems();
	},
}
</script>

<style lang="scss">
.basket-form-delivery-self {
	&__map {
		height: 40vh;
		min-height: 400px;
		width: 100%;

		@include up($md) {
			//height: 100%;
		}
	}

	ul {
		text-align: left;

		li {
			cursor: pointer;
			@include transition();

			&:not(:first-child) {
				margin-top: 16px !important;
				padding-top: 16px !important;
				border-top: 1px solid $gray-light;
			}

			&:hover,
			&:active {
				color: $golden;
			}

			&.active {
				color: $black;
			}
		}
	}
}
</style>