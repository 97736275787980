<template>
	<div class="basket-form-comments text-center">
		<h4>Добавьте пожелания</h4>
		<v-row class="mt-2">
			<v-col cols="12">
				<v-textarea v-model="form.COMMENTS"
							rows="1" auto-grow
							placeholder="Пожалуйста, напишите нам любые дополнительные комментарии к вашему заказу"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: "basket-form-comments",
		props: {
			form: {},
		},
		data() {
			return {}
		}
	}
</script>

<style lang="scss">
</style>