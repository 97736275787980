<template>
	<div class="basket-form-coupon text-center">
		<h4>Желаете оплатить сертификатом?</h4>
		<v-row class="mt-4 justify-center">
			<v-col cols="10" sm="6" md="4">
				<v-textarea v-model="form.CARD_NUMBER"
							rows="1" auto-grow
							placeholder="Номер сертификата"
				/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import vp from '@/mixins/vp';

	export default {
		name: "basket-form-coupon",
		components: {
		},
		mixins: [vp],
		props: {
			form: {}
		},
		data() {
			return {
			}
		},
		watch: {},
		computed: {
		},
		methods: {
		},
	}
</script>

<style lang="scss">

</style>