<template>
	<div class="basket-form-delivery-courier text-center">
		<h4>Укажите контактные данны<span @click.ctrl="fillForm">е</span></h4>
		<v-row class="mt-2">
			<v-col cols="12" sm="4">
				<v-text-field v-model="form.NAME"
							  label="ФИО"
							  maxlength="80"
							  required/>
			</v-col>
			<v-col cols="12" sm="4">
				<v-text-field v-model="form.EMAIL"
							  label="Email"
							  maxlength="128"
							  required/>
			</v-col>
			<v-col cols="12" sm="4">
				<v-text-field v-model="form.PHONE"
							  @keydown="filterPhoneKey"
							  @keyup="fixPhone"
							  label="Номер телефона"
							  :maxlength="phoneMaxlength"
							  required/>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	export default {
		name: "basket-form-contacts",
		components: {},
		props: {
			form: {},
		},
		data() {
			return {
				phoneMaxlength: 32,
			}
		},
		methods: {
			filterPhoneKey(e) {
				//console.log(e);
				const a = e.key;

				// ignore arrows, del, backspace
				if (
					e.keyCode === 37 ||
					e.keyCode === 39 ||
					e.keyCode === 8 ||
					e.keyCode === 46 ||
					e.keyCode === 35 ||
					e.keyCode === 36 ||
					e.keyCode === 9 ||
					(e.key === "x" && e.ctrlKey) ||
					(e.key === "c" && e.ctrlKey) ||
					(e.key === "v" && e.ctrlKey)
				)
					return true;

				if (!this.validatePhoneKey(a)) e.preventDefault();
			},
			validatePhoneKey(a) {
				if (!a) return false;
				if (!a.match(/[+0-9\(\) -]/)) return false;

				let l = this.form.PHONE?.length;
				//console.log("a: "+a+", l: "+l);
				if (l === 0) {
					if (!a.match(/[+0-9]/)) return false;
					if (a === "+") this.form.PHONE = "";
					else if (a !== "8") this.form.PHONE = "+";
					else if (a === "9") this.form.PHONE = "+7 (";
				} else if (l === 1) {
					if (this.form.PHONE === "+" && a === "8") this.form.PHONE = "";
				}
				return true;
			},
			fixPhone(e) {
				if (
					e &&
					(e.keyCode === 37 ||
						e.keyCode === 39 ||
						e.keyCode === 8 ||
						e.keyCode === 46 ||
						e.keyCode === 35 ||
						e.keyCode === 36)
				)
					return true;

				let s = this.form.PHONE;
				s = s.replace(/[^0-9]+/g, "");
				//console.log("s=" + s);
				//v = v.substr(1);

				this.phoneMaxlength = 32;
				if (!s.length) return "";
				const f = [];
				for (let i = 0; i < s.length; i++) {
					let l = s[i];
					//if ( i === 0 && l === "+" )
					//console.log(i + "=" + l);

					if (s[0] === "8") {
						// 8 ...
						this.phoneMaxlength = 17;
						if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "7") {
						// +7 ...
						this.phoneMaxlength = 18;
						if (i === 0) {
							f.push("+");
						} else if (i === 1) {
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else if (s[0] === "9") {
						// 9 ...
						this.phoneMaxlength = 18;
						if (i === 0) {
							f.push("+");
							f.push("7");
							f.push(" ");
							f.push("(");
						} else if (i === 4) {
							f.push(")");
							f.push(" ");
						} else if (i === 7) {
							f.push("-");
						} else if (i === 9) {
							f.push("-");
						}
						f.push(l);
					} else {
						// [0-6] ...
						this.phoneMaxlength = 24;
						if (i === 0) {
							f.push("+");
						} else if (i === 3) {
							f.push(" ");
						}
						f.push(l);
					}
				}
				const p = f.join("");
				if (p === this.form.PHONE) return;
				this.form.PHONE = p;
			},
			fillForm() {
				//this.form.STREET = "Решетникова 15";
				this.form.NAME = "PlayNext";
				this.form.EMAIL = "test@playnext.ru";
				this.form.PHONE = "+78126430280";
				this.form.COMMENTS = "тест, тест";
				this.form.hasAccepted = true;
			}
		},
	}
</script>

<style lang="scss">
</style>