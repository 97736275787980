import Vue from "vue";
import store from "../store";
import Config from "../config";

/**
 * Reverse geocoding turns geographic coordinates into place names, turning -77.050, 38.889 into 2 Lincoln Memorial Circle NW.
 */
export function geodecode() {
    let geo = store.state.geo;
    Vue.set(geo.decoded, "lat", geo.lat);
    Vue.set(geo.decoded, "lng", geo.lng);
    const url = "https://api.mapbox.com/geocoding/v5/mapbox.places/"
        + geo.lng + "," + geo.lat
        + ".json?types=address"	//,locality,neighborhood,district
        + "&access_token=" + Config.MAPBOX_ACCESS_TOKEN;

    //console.log("Geo decode request: ", url);

    httpModule.getJSON(url).then((result) => {
        //console.log("Geo decode response: ", result.features[0].context);
        if (result && result.features[0]) {
            let address = [];
            if (result.features[0].text) address.push(result.features[0].text);
            if (result.features[0].address) address.push(result.features[0].address);
            Vue.set(geo.decoded, "updatedAt", new Date());
            Vue.set(geo.decoded, "address", address.join(", "));

            for (let c of result.features[0].context) {
                if (c.id.match(/^country.+/)) {
                    if (c.text) {
                        c.text = c.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                        Vue.set(geo.decoded, "country", c.text);
                    }
                } else if (c.id.match(/^place.+/)) {
                    if (c.text) {
                        c.text = c.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                        Vue.set(geo.decoded, "city", c.text);
                        console.log("Geodecoded city: " + c.text);
                    }
                }
            }

        } else {
            Vue.set(geo.decoded, "city", null);
            Vue.set(geo.decoded, "district", null);
            Vue.set(geo.decoded, "address", null);
        }
        /*console.log("Geo decoded: ",
            geo.decoded.country
            + ", " + geo.decoded.city
            + ", " + geo.decoded.district
            + ", " + geo.decoded.address);*/

    }, (err) => {
        console.warn("Geo decoding request error: ", err);
    });
}

/**
 * Reverse geocoding turns geographic coordinates into place names, turning -77.050, 38.889 into 2 Lincoln Memorial Circle NW.
 */

export function geodecodeYandex(coords) {
    if (!coords) {
        let geo = store.state.geo;
        coords = {lat: geo.lat, lng: geo.lng};
    }
    // TODO
    /*console.log("GeocodingYandex: ", address);
    const url = "https://geocode-maps.yandex.ru/1.x"
        + "?apikey="+Config.YANDEX_MAP_API_KEY
        + "&geocode="+ encodeURIComponent(address)
        + (type?"&kind="+type:"")	// house,street,metro,district,locality
        + "&rspn=0"
        + "&format=json"
        + "&lang=ru_RU"
        + "&results=10";
    console.log("GeocodeYandex request: ", url);

    return new Promise((resolve, reject) => {
        httpModule.getJSON(url).then((result) => {
            //console.log("Geocoded response: ", result);

            if (result && result.features) {
                //console.log("Geocoded: " + result.features.length + " features");
                resolve(result.features);
            }
            else reject(result);
        });
    });*/
}

/**
 * Geocoding, turns address into geographic coordinates: Moskovskiy pr. into -77.050, 38.889.
 * todo Использует MapBox!
 * @deprecated Use geocodeYandex!
 */
export function geocode(address, types) {
    console.log("Geocoding: ", address);
    const url = "https://api.mapbox.com/geocoding/v5/mapbox.places/"
        + encodeURIComponent(address)
        + ".json?language=ru"
        //+ "&types=district,place,locality,address"
        + (types ? "&types=" + types : "")
        + "&country=RU"
        + "&limit=10"
        + "&access_token=" + Config.MAPBOX_ACCESS_TOKEN;

    //console.log("Geocode request: ", url);

    return new Promise((resolve, reject) => {
        httpModule.getJSON(url).then((result) => {
            //console.log("Geocoded response: ", result);

            if (result && result.features) {
                //console.log("Geocoded: " + result.features.length + " features");
                resolve(result.features);
            } else reject(result);
        });
    });
}

/**
 * Geocoding, turns address into geographic coordinates: Moskovskiy pr. into -77.050, 38.889.
 */
export function geocodeYandex(address) {
    console.log("GeocodingYandex: ", address);
    const url = "https://geocode-maps.yandex.ru/1.x"
        + "?apikey=" + Config.YANDEX_MAP_API_KEY
        + "&geocode=" + encodeURIComponent(address)
        //+ (type?"&kind="+type:"")	// house,street,metro,district,locality
        + "&rspn=0"
        + "&format=json"
        + "&lang=ru_RU"
        + "&results=10";
    //console.log("GeocodeYandex request: ", url);

    return new Promise((resolve, reject) => {
        fetch(url).then(res => res.json()).then((result) => {
            console.log("Geocoded response: ", result);
            if (result?.response?.GeoObjectCollection?.featureMember) resolve(result?.response?.GeoObjectCollection?.featureMember);
            else reject();
        });
    });
}

/**
 * Returns distance between two points in meters.
 */
export function distance(coords1, coords2) {
    const lat1 = coords1[0];
    const lon1 = coords1[1];
    const lat2 = coords2[0];
    const lon2 = coords2[1];
    let R = 6371e3; // measure in meters
    let dLat = (lat2 - lat1) * Math.PI / 180;
    let dLon = (lon2 - lon1) * Math.PI / 180;
    let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    //if (d>1) return Math.round(d)+"km";
    //else if (d<=1) return Math.round(d*1000)+"m";
    return d;
}

