<template>
	<div class="basket"
		 :class="{'basket__with-banner':banner}">
		<v-container v-if="isEmpty" class="text-center">
			<h1 class="h3 mb-16">Ваша корзина пуста</h1>
		</v-container>
		<div v-else class="basket__wrapper mx-auto padding-top-main">
			<basket-items :items="items"/>
			<basket-form/>
		</div>
	</div>
</template>

<script>
	//import {yandexMap, ymapMarker, loadYmap} from 'vue-yandex-maps';
	import BasketForm from "@/views/Basket/basket-form";
	import BasketItems from "@/views/Basket/basket-items";

	export default {
		name: "basket",
		components: {
			BasketItems,
			BasketForm,
		},
		computed: {
			isEmpty() {
				return !this.$store.state.session?.basket?.length;
			},
			items() {
				return this.$store.state.session?.basket;
			},
			banner() {
				return this.$store.state.common.banner;
			}
		},

	}
</script>

<style lang="scss">
	.basket {
		margin-top: 128px;

		&__with-banner {
			margin-top: 168px;
		}

		&__wrapper {
			position: relative;
			@include up($sm) {
				max-width: 952px;
			}
			@include up($lg) {
				max-width: 1048px;
			}

		}


	}
</style>