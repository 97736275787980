<template>
	<div class="basket-form-destinations text-center" id="basket-form-destinations">
		<h4>Выберите пункт назначени<span @click.ctrl="fillForm">я</span></h4>

		<div class="mt-2 d-flex justify-center">
			<v-radio-group v-model="form.destinationId" :row="isSM" @change="onSelect">
				<v-radio v-for="(item, index) of destinations"
						 :label="item.label"
						 :value="item.id"
						 :key="'destination'+index"/>
			</v-radio-group>
		</div>
		<p v-if="isCourierOnly" class="mt-2">Для выбранного пункта назначения возможна только курьерская доставка</p>
	</div>
</template>

<script>
	import vp from '@/mixins/vp';
	import {scrollTo} from '@/utils/utils';
	import {date2dmy} from '@/utils/date';
	import {destinations, DESTINATION_SPB, DELIVERY_TYPE_EXPRESS} from '@/store/delivery';

	export default {
		name: "basket-form-destinations",
		mixins: [vp],
		props: {
			form: {},
		},
		data() {
			return {
			}
		},
		computed: {
			destinations() {
				return destinations;
			},
			destination() {
				return this.destinations.find(el => el.id === this.form.destinationId);
			},
			isCourierOnly() {
				return this.destination && !this.destination.deliveryTypeIds?.length;
			}
		},
		methods: {
			onSelect() {
				this.form.CITY = this.destination.city;
				this.form.STREET = null;
				this.form.deliveryTypeId = null;
				this.form.deliveryZoneId = null;

				/*this.loading = true;
				this.error = null;
				this.$store.dispatch('post', {
					action: 'BasketDeliveriesController',
					params: {
						cityId: this.form.destinationId
					}
				}).then((res) => {
					//this.isSuccessDialog = true;
				}).catch((error) => {
					// this.error = error;
					this.error = "Что-то пошло не так. Пожалуйста, попробуйте, еще раз.";
					this.isErrorDialog = true;
				}).finally(() => {
					this.loading = false;
				});*/

				setTimeout(() => {
					scrollTo("basket-form-destinations", -120);
				}, 300);

				this.$emit("select");
			},
			fillForm() {
				//this.form.STREET = "Решетникова 15";
				this.form.destinationId = DESTINATION_SPB;
				this.form.deliveryTypeId = DELIVERY_TYPE_EXPRESS;
				this.form.deliveryZoneId = 202;
				this.form.DATE_DELIVERY = date2dmy(new Date());
				this.form.TIME = "с 10:00 до 13:00";
				this.form.CITY = this.destination?.city || "Новгород";
				this.form.STREET = "Решетникова 15";
				this.form.APT = "122";
				this.form.NAME = "PlayNext";
				this.form.EMAIL = "test@playnext.ru";
				this.form.PHONE = "+78126430280";
				this.form.COMMENTS = "тест, тест";
				this.form.hasAccepted = true;
			}
		},
	}
</script>

<style lang="scss">

</style>