export const PAYMENT_SYSTEM_ID_CARD = 2;
export const PAYMENT_SYSTEM_ID_CASH = 3;
export const PAYMENT_SYSTEM_ID_SELF = 4;	// оплата на кассе при самовывозе
export const PAYMENT_SYSTEM_ID_DEFERRED = 5;	// оплата после созвона с менеджером

// todo забирать все из бэка

export const paymentSystems = [
	{
		id: PAYMENT_SYSTEM_ID_CASH,
		label: "Наличными курьеру",
	},
	{
		id: PAYMENT_SYSTEM_ID_CARD,
		label: "Картой на сайте",
	},
	{
		id: PAYMENT_SYSTEM_ID_SELF,
		label: "Наличными при самовывозе",
	},
	{
		id: PAYMENT_SYSTEM_ID_DEFERRED,
		label: "Выбор формы платежа после созвона с менеджером",
	}
];
