<template>
	<div v-if="postcards && postcards.length" class="basket-form-postcards text-center" id="basket-form-postcards">
		<h4>Выберите открытку</h4>

		<div class="basket-form-postcards__items mt-10">
			<div
				class="basket-form-postcards__item text-center d-flex flex-column align-center justify-space-between mb-6 mb-md-0"
				@click="onCancel()">
				<i class="img"></i>
				<div class="h5 mt-2">без открытки</div>
			</div>
			<div v-for="(item, index) of postcards" :key="'index' + index"
				 class="basket-form-postcards__item text-center d-flex flex-column align-center justify-space-between mb-6 mb-md-0"
				 :class="{'--active': form.POSTCARD_ID===item.ID}"
				 @click="onPostcardClick(item)">
				<template v-if="item.PRICE">
					<v-img :src="item.PREVIEW_PICTURE" class="img"/>
					<div class="h5 mt-2">{{ item.PRICE | price }} <span>₽</span></div>
				</template>
				<template v-else>
					<i class="img"></i>
					<div class="h5 mt-2">без открытки</div>
				</template>
			</div>
		</div>

		<transition name="fade">
			<div v-if="selectedItem" class="mt-10" id="basket-form-postcards__postcard">
				<div class="basket-form-postcards__image"
					 :class="{rotated: withPostCardText, squared: selectedItem.IS_SQUARE === true}">
					<v-img :src="selectedItem.PREVIEW_PICTURE" contain/>

					<div class="basket-form-postcards__text"
						 :class="{opened: withPostCardText, closed: withPostCardTextClosing}"
					>
						<svgicon name="cross" @click="onPostcardTextClose" class="close"/>
						<transition name="fade">
							<v-textarea v-if="withPostCardInput"
										v-model="form.POSTCARD_TEXT"
										rows="1" auto-grow
										row-height="96"
										placeholder="Напишите хорошие слова своему любимому человеку"
										:maxlength="500"
							/>
						</transition>
						<!--                      <BtnPrimary class="d-block large mx-auto"
														  label="Отправить"/>-->
					</div>
				</div>

				<div class="mt-6 d-flex flex-column align-center">
					<transition name="fade" mode="out-in">
						<btn-primary v-if="withPostCardText||withPostCardTextClosing"
									 class="d-block large mx-auto"
									 label="Сохранить"
									 @click="onPostcardTextClose" key="btn1"
						/>
						<btn-primary v-else
									 class="d-block large"
									 label="Добавить пожелание"
									 @click="onAddText" key="btn2"
						/>
					</transition>
					<btn-minor class="mt-4"
							   label="Убрать открытку"
							   @click="onCancel"/>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import BtnPrimary from '@/components/btn-primary';
import BtnText from '@/components/btn-text';
import BtnMinor from '@/components/btn-minor';
import vp from '@/mixins/vp';
import Vue from 'vue';
import {scrollTo} from '@/utils/utils';

export default {
	name: "basket-form-postcards",
	components: {
		BtnPrimary,
		BtnText,
		BtnMinor,
	},
	mixins: [vp],
	props: {
		form: {}
	},
	data() {
		return {
			isAddToBasketLoading: false,
			withPostCardText: false,
			withPostCardTextClosing: false,
			withPostCardInput: false,
		}
	},
	watch: {},
	computed: {
		postcards() {
			return this.$store.state.page.BasketController.postcards;
		},
		selectedItem() {
			if (!this.form.POSTCARD_ID) return null;
			return this.postcards.find(el => el.ID === this.form.POSTCARD_ID);

		},
	},
  emits: ['onPostcardClick', 'onPostcardCancel'],
	methods: {

		onCancel() {
			this.form.POSTCARD_ID = 0;
			this.form.POSTCARD_PRICE = 0;

			this.withPostCardText = false;
			this.withPostCardTextClosing = false;
			this.withPostCardInput = false;

      this.$emit('onPostcardCancel');
		},
		onPostcardClick(item) {
			this.form.POSTCARD_ID = item.ID;
			this.form.POSTCARD_PRICE = item.PRICE;

			if (this.form.POSTCARD_ID) {
				setTimeout(() => {
					scrollTo("basket-form-postcards__postcard", -120);
				}, 300);
			}

      this.$emit('onPostcardClick');
		},
		onPostcardTextClose() {
			this.withPostCardInput = false;
			this.withPostCardTextClosing = true;
			this.withPostCardText = false;

			setTimeout(() => {
				this.withPostCardTextClosing = false;
			}, 350);
		},
		onAddText() {
			/*const img = document.getElementById("basket-form-postcards__img");
			const text = document.getElementById("basket-form-postcards__text");
			text.style.width = img.getBoundingClientRect().width + "px";*/

			this.withPostCardText = true;
			setTimeout(() => {
				this.withPostCardInput = true;
			}, 600);
		}
	},
	mounted() {

	},
}
</script>

<style lang="scss">
.basket-form-postcards {

	&__items {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__item {
		margin: 0 10px;
		cursor: pointer;
		@include transition();

		&:hover {
			.img {
				transform: translateY(-5px);
			}
		}

		.img {
			display: block;
			width: 150px;
			height: 150px;
			border: 2px solid transparent;
			background: #ededed;
			@include transition();

			@include up($lg) {
				width: 180px;
				height: 180px;
			}
		}

		&.--active {
			.v-image, i {
				border: 2px solid $golden;
			}
		}

		.h5 {
			span {
				font-size: 14px;
				line-height: 20px;
			}
		}

	}

	&__image {
		margin: 0 auto;
		max-width: 300px;
		height: 300px;
		position: relative;

		@include up($sm) {
			max-width: 480px;
			height: 350px;
		}

		@include up($md) {
			max-width: 600px;
			height: 400px;
		}

		.v-image {
			height: 100%;
			transition: all .6s linear;
		}

		&.rotated {
			.v-image {
				transform: rotateY(180deg);
			}
		}

		&.squared {

			@include up($sm) {
				max-width: 350px;
				height: 350px;
			}

			@include up($md) {
				max-width: 400px;
				height: 400px;
			}
		}
	}

	&__text {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		//width: 100%;
		//background: rgba(237, 225, 207, 0.85); //local color
		background: rgba(206, 206, 206, 0.95);
		//color: $black;
		padding: 30px;
		//opacity: 0.6;
		transform: rotateY(90deg);
		transition: all .3s .3s linear;
		backface-visibility: hidden;

		&.opened {
			transform: rotateY(0);
			//opacity: 1;
		}

		&.closed {
			transition: all .3s 0s linear !important;
		}

		.close {
			position: absolute;
			stroke: $default-text-color;
			top: 24px;
			right: 24px;
			width: 24px;
			height: 24px;
			cursor: pointer;
			@include transition();

			&:hover {
				stroke: $black;
			}
		}

		& textarea {
			font-family: $body-font-family;
			font-size: $fs-3;
			line-height: $lh-3;
			letter-spacing: $ls-1;
			color: $black !important;
			@include up($md) {
				font-size: $fs-2;
				line-height: $lh-2;
			}
			@include up($lg) {
				font-size: $fs-1;
				line-height: $lh-1;
			}
		}
	}
}
</style>