<template>
	<div class="basket-form-agreement d-flex justify-center">
		<v-checkbox v-model="form.hasAccepted" hide-details dense>
			<template slot="label">
				Я прочитал и принимаю&nbsp;
				<router-link :to="{name:'terms'}"
							 target="_blank"
							 @click.native.stop="">Общие условия продажи
				</router-link>
			</template>
		</v-checkbox>
	</div>
</template>

<script>
	export default {
		name: "basket-form-agreement",
		props: {
			form: {},
		},
		data() {
			return {}
		}
	}
</script>

<style lang="scss">
</style>