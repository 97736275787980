<template>
  <div v-if="withPayment" class="basket-form-delivery-types text-center" id="basket-form-delivery-types">
    <h4>Оплата заказа</h4>

    <div v-if="paymentSystems && paymentSystems.length" class="mt-2 d-flex justify-center">
      <v-radio-group v-model="form.paymentSystemId" :row="isSM" @change="onSelect">
        <v-radio v-for="(item, index) of paymentSystems"
                 :label="item.label"
                 :value="item.id"
                 :key="'payment-system-'+index"
                 :class="'destination'+destinationCity"
        />
      </v-radio-group>
    </div>
    <div v-else class="mt-2 d-flex justify-center">
      {{ defaultPaymentSystem.label }}
    </div>
  </div>
</template>

<script>
import vp from '@/mixins/vp';
import {scrollTo} from '@/utils/utils';
import {PAYMENT_SYSTEM_ID_SELF, PAYMENT_SYSTEM_ID_DEFERRED, paymentSystems} from '@/store/payment';
import {deliveryTypes} from '@/store/delivery';
import {destinations} from "../../store/delivery";

export default {
  name: "basket-form-delivery-types",
  props: {
    form: {},
  },
  mixins: [vp],
  data() {
    return {}
  },
  watch: {
    "form.deliveryTypeId": {
      immediate: true,
      handler(deliveryTypeId) {
        if (this.paymentSystems?.length === 1) this.form.paymentSystemId = this.paymentSystems[0].id;
        else if (this.paymentSystems?.length >= 1) this.form.paymentSystemId = null;
        else this.form.paymentSystemId = PAYMENT_SYSTEM_ID_DEFERRED;

        //console.log("PAYMENT SYSTEM SET: "+this.form.paymentSystemId);
      }
    }
  },
  computed: {
    withPayment() {
      return this.form.deliveryTypeId !== null;
    },
    defaultPaymentSystem() {
      return paymentSystems.find(el => el.id === PAYMENT_SYSTEM_ID_DEFERRED);
    },
    deliveryType() {
      return deliveryTypes.find(el => el.id === this.form.deliveryTypeId);
    },
    paymentSystems() {
      return paymentSystems.filter(el => this.deliveryType?.paymentSystemIds?.some(id => id === el.id));
    },
    destinationCity() {
      return destinations.id;
    }
  },
  methods: {
    onSelect() {
      /*setTimeout(() => {
        scrollTo("basket-form-payment", -120);
      }, 300);*/

      this.$emit("select");
    },
  },
}
</script>

<style lang="scss">

</style>